import React from 'react';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import './styles.scss'; 
import { useTranslation } from 'react-i18next';

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

export const CalendarWeekDays = () => {
    const { t } = useTranslation();
    const dayOfTheWeekNames = dayjs.weekdays(true);
    const dayOfTheWeekAbbreviations = dayjs.weekdaysShort(true);

    return (
        <div className='calendarWeekdaysWrapper'>
        
            {dayOfTheWeekAbbreviations.map((dayOfTheWeekAbbreviation, index) => (
                <div className='calendarWeekday' title={dayOfTheWeekNames[index]} key={dayOfTheWeekAbbreviation}>
                    <span className='calendarWeekdayAspectRatioBox'>
                        <div className='calendarWeekdayAbbreviation' data-text={dayOfTheWeekAbbreviation.toUpperCase()}>
                            {t(dayOfTheWeekAbbreviation.toUpperCase())}
                        </div>
                    </span>
                </div>
            ))}
        </div>
    );
};

