import { useEffect, useRef } from 'react';

/**
 * Calls function on component update or inputs change phase after mounting
 * @param fn function
 * @param inputs
 */
export const useDidUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            fn();
        } else {
            didMountRef.current = true;
        }
    }, [inputs, fn]);
};