import React from 'react';
import { useTranslation } from "react-i18next";
import { Pagination } from "react-bootstrap";

import { getEnvData } from "helpers";

export default function TablePagination({
    zeroBasedPageIndex = false,
    currentPage,
    goToPage,
    list,
    pageSize = getEnvData.PAGE_SIZE
}) {

    const { t } = useTranslation();
    const initialPage = zeroBasedPageIndex ? 0 : 1;

    return (
        <div className="pagination-container">
            <Pagination >
                <Pagination.Item
                    key="prev"
                    onClick={() => (currentPage !== initialPage) && goToPage(currentPage - 1)}
                    disabled={currentPage === initialPage}
                >
                    {t("PREVIOUS_PAGE")}
                </Pagination.Item>
                <Pagination.Item
                    key="next"
                    onClick={() => (list.length === pageSize) && goToPage(currentPage + 1)}
                    disabled={list.length < pageSize}
                >
                    {t("NEXT_PAGE")}
                </Pagination.Item>
            </Pagination>
        </div>
    );
}