export const FileTypes = {
    ZIP:'application/zip',
    PDF: 'application/pdf',
    CSV: 'text/csv',
    NONE: '',
};

export const FileFormat = {
    PDF: 'PDF',
    CSV: 'CSV',
};
