import { combineReducers } from 'redux';
import login from './login';
import reservations from './reservations';
import invoices from './invoices';
import drivers from './drivers';
import vehicles from './vehicles';
import admin from './admin';
import customerType from './customerType';
import country from './country';
import notesCategories from './category';
import notes from './notes';

export default combineReducers({
    login,
    reservations,
    invoices,
    drivers,
    vehicles,
    admin,
    customerType,
    country,
    notesCategories,
    notes,
});
