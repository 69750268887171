
import React, { useEffect, useState, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import times from 'lodash/times';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'contexts/language';
import { CalendarMonth } from './_private/CalendarMonth';
import { ChevronLeftIcon } from 'components/ChevronLeftIcon/ChevronLeftIcon';
import { ChevronRightIcon } from 'components/ChevronRightIcon/ChevronRightIcon';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';
import { DatePickerFilters } from './DatePickerFilters';
import { ReactComponent as ChevronDown } from 'images/icons/chevron_down_24.svg';
import { CalendarQuickFilters } from 'enums/CalendarQuickFilters';
import { Button } from 'components/Button';

import './styles.scss';

const now = dayjs();

const DateRangePicker = ({
    day,
    numberOfMonths = 1,
    startDay,
    setStartDay,
    endDay,
    setEndDay,
    onDateChange,
    disableFuture=false,
    snake = true,
    hasNavigation = true,
    shouldDisableDay,
    currentSelection,
    onDateClick,
    onApplyButtonClick
}) => {
    const { t } = useTranslation();
	const [language] = useContext(LanguageContext);

    const [date, setDate] = useState(startDay ? startDay : (day ? dayjs(day) : dayjs()));
    const [hoveredDay, setHoveredDay] = useState(null);
    const [hasRangeStarted, setHasRangeStarted] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(CalendarQuickFilters.last30Days);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef= useRef(null);

    const handleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if(dropdownRef.current && !dropdownRef.current.contains(event.target)){
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    },[]);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        switch (filter) {
            case CalendarQuickFilters.last30Days:
                setStartDay(dayjs().subtract(30, 'days'));
                setEndDay(dayjs());
                setDate(dayjs().subtract(30, 'days'));
                break;
            case CalendarQuickFilters.last3Months:
                setStartDay(dayjs().subtract(3, 'months'));
                setEndDay(dayjs());
                setDate(dayjs().subtract(3, 'months'));
                break;
            case CalendarQuickFilters.currentYear:
                setStartDay(dayjs().startOf('year'));
                setEndDay(dayjs());
                setDate(dayjs().startOf('year'));
                break;
            default:
                resetDatePicker();
                break;
        }
    };

    const changeMonth = (by) => {
        let newDate;
        if (by > 0) {
            newDate = date.clone().add(1, 'month');
        } else {
            newDate = date.clone().subtract(1, 'month');
        }
        setDate(newDate);
    };

    const resetDatePicker = () => {
        setStartDay(undefined);
        setEndDay(undefined);
        setHasRangeStarted(false);
        setHoveredDay(null);
    };


    const handleApplyButtonClick= () =>{
        onApplyButtonClick();
        setIsDropdownOpen(false);
    };

    const isCustomRange = (start, end) => {
        const last30Days = dayjs().subtract(30, 'days').startOf('day');
        const last3Months = dayjs().subtract(3, 'months');
        const currentYear = dayjs().startOf('year');
    
        if (start.isSame(last30Days, 'day') && end.isSame(now, 'day')) {
            return false;
        }
        if (start.isSame(last3Months, 'day') && end.isSame(now, 'day')) {
            return false;
        }
        if (start.isSame(currentYear, 'day') && end.isSame(now, 'day')) {
            return false;
        }
        return true;
    };
    
    useDidUpdateEffect(() => {
        if (startDay && endDay) {
            if (isCustomRange(startDay, endDay)) {
                setSelectedFilter(CalendarQuickFilters.customRange);
            }
            onDateChange && onDateChange(startDay, endDay);
        }
    }, [startDay, endDay]);

    const renderPrevBtn = () => {

        const eighteenMonthsAgo = now.clone().subtract(18, 'months');
        const isDisabled = date.isBefore(eighteenMonthsAgo, 'month');
       
        return (
            <ChevronLeftIcon
                onClick={() => !isDisabled && changeMonth(-1)}
                className={`navButton previousButton ${isDisabled ? 'disabled' : ''}`}
            />
        );
    };

    const renderNextBtn = () => {
        
        const isDisabled = date.diff(now, 'month') >= 0 && now.month() === date.month();

        return (
            <ChevronRightIcon
                onClick={() => !isDisabled && changeMonth(+1)}
                className={`navButton nextButton ${isDisabled ? 'disabled' : ''}`}
            />
        );
    };
    return (
    <div className="date-range-picker">
            <label htmlFor="selectedRange" className="input-label">{t('SELECT_DATE_PERIOD')}</label>
            <div ref={dropdownRef} className="input-container">
                <input
                    type="text"
                    id="selectedRange"
                    className="input-field"
                    readOnly
                    value={`${startDay ? startDay.locale(language).format('DD MMM YY') : ''} - ${endDay ? endDay.locale(language).format('DD MMM YY') : ''}`}
                    onClick={handleDropdown}
                />
                <ChevronDown className="dropdownIconClass" />
                {isDropdownOpen &&
                <div className="calendarViewContainer">
                    <div className="datePickerContainer">
                        <div className="filterClass">
                            <DatePickerFilters
                            selectedFilter={selectedFilter}
                            onFilterChange={handleFilterChange}
                            />
                        </div>
                        <div className="datePicker">
                            {hasNavigation && renderPrevBtn()}
                            {times(numberOfMonths, num => (
                            <CalendarMonth
                            index={num}
                            key={num}
                            startDay={startDay}
                            setStartDay={setStartDay}
                            endDay={endDay}
                            setEndDay={setEndDay}
                            currentDate={date}
                            setHoveredDay={setHoveredDay}
                            hoveredDay={hoveredDay}
                            snake={snake}
                            disablePast={false}
                            disableFuture={disableFuture}
                            hasRangeStarted={hasRangeStarted}
                            setHasRangeStarted={setHasRangeStarted}
                            shouldDisableDay={shouldDisableDay}
                            currentSelection={currentSelection}
                            onDateClick={onDateClick}
                            />
                            ))}
                            {hasNavigation && renderNextBtn()}
                        </div>
                    </div>
                    <div className='buttonContainer'>
                        <Button className="buttonClass" type='submit' onClick={handleApplyButtonClick} disabled={!(startDay && endDay)} text={t('APPLY')}/>

                    </div>
                </div> 
                }
            </div>
        </div>
    );
};
export default DateRangePicker;