import { INVOICES, INVOICES_LOADED, INVOICES_LOADING } from './types';
import * as service from 'services/invoices';
import { toastLogout } from 'helpers';

export const getInvoices = params => async dispatch => {
    try {
        dispatch({ type: INVOICES_LOADING });
        const response = await service.getInvoices(params);
        if (response) {
            dispatch({
                type: INVOICES,
                invoices: response.data,
            });
            return response.data;
        }
    } catch (error) {
        toastLogout(error);
    } finally {
        dispatch({ type: INVOICES_LOADED });
    }
};

export const clearInvoices = () => {
    return {
        type: INVOICES,
        invoices: null
    };
};