import React, { createContext, useContext, useEffect, useState } from 'react';
import { getEnvData } from 'helpers';

const AnnouncementContext = createContext();

export const AnnouncementContextProvider = ({ children }) => {
    const [isChristmasBannerShown, setIsChristmasBannerShown] = useState(false);
    const [isPayportBannerShown, setIsPayportBannerShown] = useState(false);
    const [isChristmasBannerDismissed, setIsChristmasBannerDismissed] = useState(false);
    const [isPayportBannerDismissed, setIsPayportBannerDismissed] = useState(false);


    useEffect(() => {
        const hasChristmasBannerExpired = new Date() >= getEnvData?.CHRISTMAS_BANNER_EXPIRY_DATE;
        setIsChristmasBannerShown(!hasChristmasBannerExpired && !isChristmasBannerDismissed);
    }, [isChristmasBannerDismissed]);

    useEffect(() => {
        const hasPayportBannerExpired = new Date() >= getEnvData?.PAYPORT_BANNER_EXPIRY_DATE;
        setIsPayportBannerShown(!hasPayportBannerExpired && !isPayportBannerDismissed);
    },[isPayportBannerDismissed]);

    const dismissChristmasBanner = () => {
        setIsChristmasBannerDismissed(true);
    };

    const dismissPayportBanner = () => {
        setIsPayportBannerDismissed(true);
    };

    return (
        <AnnouncementContext.Provider
            value={{
                isChristmasBannerShown,
                dismissChristmasBanner,
                isPayportBannerShown, 
                dismissPayportBanner
            }}
        >
            {children}
        </AnnouncementContext.Provider>
    );
};

/**
 * @typedef {Object} AnnouncementContext
 * @property {boolean} isChristmasBannerShown
 * @property {() => void} dismissChristmasBanner
 */

/**
 * @returns {AnnouncementContext} AnnouncementContext
 */
export const useAnnouncementContext = () => {
    const context = useContext(AnnouncementContext);

    if (context === undefined) {
        throw new Error('useAnnouncementContext must be used within a AnnouncementContextProvider');
    }

    return context;
};