import React from 'react';
import * as S from './Banner.styled.js';

export const BannerType = {
  Error: 'error',
  Success: 'success',
  Warning: 'warning',
  Info: 'info',
};

export const Banner = ({
  headerText,
  text,
  bannerType,
  isSticky = false,
  isHeaderCentered = true,
  className,
  buttonClass,
  buttonText,
  onButtonClick = () => {},
  hasCloseIcon = false,
  onCloseClick = () => {},
}) => {
  return (
    <S.Wrapper bannerType={bannerType} isSticky={isSticky} className={className}>
      <S.Header bannerType={bannerType} isCentered={isHeaderCentered}>
        <S.HeaderText>{headerText}</S.HeaderText>
        {!!hasCloseIcon && <S.CloseIcon onClick={onCloseClick} />}
      </S.Header>
      <S.Text>{text}</S.Text>
      {!!buttonText && <S.BannerButton text={buttonText} isSmall onClick={onButtonClick} className={buttonClass} />}
    </S.Wrapper>
  );
};
