import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { matchRoute, sendGoogleAnalyticsEvent } from 'helpers';
import sideMenuList from './sideMenuList';
import Bell from 'images/icons/bell.svg';
import './styles.scss';

// SVG Icons import
import { ReactComponent as DashboardIcon } from '../../images/icons/ico_house.svg';
import { ReactComponent as DocumentsIcon } from '../../images/icons/documents_24.svg';
import { ReactComponent as ManageRidesIcon } from '../../images/icons/ico_pins.svg';
import { ReactComponent as AvailableRidesIcon } from '../../images/icons/ico_calendar.svg';
import { ReactComponent as PastRidesIcon } from '../../images/icons/ico_carSharing.svg';
import { ReactComponent as DriversIcon } from '../../images/icons/ico_mydriver_orange.svg';
import { ReactComponent as VehiclesIcon } from '../../images/icons/ico_vehicles_orange.svg';
import { ReactComponent as DispatchersIcon } from '../../images/icons/ico_dispatcher_orange.svg';
import { ReactComponent as ApprovalsIcon } from '../../images/icons/ico_clipboard_checked.svg';
import { ReactComponent as PurchasingIcon } from '../../images/icons/pricing-ico.svg';
import { ReactComponent as VatInvoiceIcon } from '../../images/icons/contract_24.svg';
import { ReactComponent as BusinessIcon } from '../../images/icons/business.svg';

const MENU_ICONS_MAPPING = {
	dashboard: DashboardIcon,
	manageRides: ManageRidesIcon,
	availableRides: AvailableRidesIcon,
	paymentReports: DocumentsIcon,
	pastRides: PastRidesIcon,
	drivers: DriversIcon,
	vehicles: VehiclesIcon,
	dispatchers: DispatchersIcon,
	approvals: ApprovalsIcon,
	tspNotes: ApprovalsIcon,
	pricing: PurchasingIcon,
	vatInvoice: VatInvoiceIcon,
	profile: BusinessIcon
};

const LeftNavigation = ({
	askNotificationPermission = () => {
		// default implementation
	},
	notificationEnabled = false,
	isNotificationSupported = false,
	adminFlow,
	showVatInvoice,
	hasAccessToPricing
}) => {
	const { t } = useTranslation();
	const [navMenuList, setNavMenuList] = useState(sideMenuList);

	useEffect(() => {
		let updatedMenuList = hasAccessToPricing ? sideMenuList : sideMenuList.filter(menu => menu.id !== 'pricing');
		updatedMenuList = showVatInvoice ? updatedMenuList : updatedMenuList.filter(menu => menu.id !== 'vatInvoice');

		setNavMenuList(updatedMenuList);
	}, [hasAccessToPricing, showVatInvoice]);

	function handleMenuClick(tab = "") {
		tab = tab.toLowerCase();
		sendGoogleAnalyticsEvent(`side_menu_initial_tab_${tab}`, {
			event_category: "home",
			event_label: `final_tab_${tab}`
		});
	}

	const getIcon = menuItem => {
		if (menuItem.icon.endsWith('svg')) {
			const Icon = MENU_ICONS_MAPPING[menuItem.id];
			return <Icon className="menu-icon" />;
		} else {
			return <img
				className="menu-icon"
				src={require(`../../images/icons/${menuItem.icon}`).default}
				alt={menuItem.text}
			/>;
		}
	};


	const renderMenu = () => (
		<>
			{navMenuList.map((menuItem) => (
				(adminFlow || !menuItem?.onlyAdmin) && <NavLink
					className="menu"
					to={menuItem.path}
					activeClassName="active"
					isActive={match => match || matchRoute("/" + menuItem.id)}
					search={menuItem.search || ""}
					key={menuItem.id}
					onClick={() => handleMenuClick(menuItem.text)}
				>
					{getIcon(menuItem)}
					<span className="menu-text">{t(menuItem.text)}</span>
				</NavLink>
			))}
			{isNotificationSupported && !notificationEnabled && (
				<a href="/" className="notification notification-step" onClick={askNotificationPermission} >
					<img src={Bell} alt="notification" className="icon" />
					<span>{t("GET_NOTIFICATIONS")}</span>
				</a>
			)}
		</>
	);

	return (
		<div className="side-menu">
			{renderMenu()}
		</div>
	);
};

export default LeftNavigation;

/* sub-menu structure for menu list

{
				"id": "myRides",
				"icon": "MyRidesLogo",
				"text": "My Rides",
				"subMenu": [
						{
								"id": "dispatchRides",
								"text": "Dispatch Rides"
						},
						{
								"id": "allRides",
								"text": "All Rides"
						}
				]
		},

*/