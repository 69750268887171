export default (state, action) => {
    switch (action.type) {
        case 'INVOICE_NUMBER':
            return {
                ...state,
                invoice_number: action.payload,
            };

        default:
            return state;
    }
};
