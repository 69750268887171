import React, { useRef } from 'react';
import './style.scss';


export const CalendarDay = ({
  date,
  isDisabled = false,
  isSelected = false,
  onClick,
  onMouseEnter,
  isSelectionStart = false,
  isSelectionEnd = false,
  isToday = false,
  isInCurrentMonth = true,
}) => {
  const mobileTouchRef = useRef(false);

  const onDayClick = () => {
    onClick(date, isDisabled);
  };


  const onDayMouseEnter = () => {
    if (!mobileTouchRef.current && isInCurrentMonth) {
      onMouseEnter(!isDisabled ? date : null);
    }
  };

  const dayClasses = [
      'calendarDay',
      isDisabled && 'disabled-style',
      isSelected && 'selected',
      isToday && 'today',
      isSelectionStart && 'selection-start',
      isSelectionEnd && 'selection-end',
      isInCurrentMonth && 'in-current-month',
  ].filter(Boolean).join(' ');

  return (
      <div className='calendarDayWrapper'>
          <time
              className='calendarDayAspectRatioBox'
              dateTime={date.format('YYYY-MM-DD')}
              onMouseEnter={onDayMouseEnter}>
              {isInCurrentMonth && (
                  <button
                      type='button'
                      className={dayClasses}
                      disabled={!isInCurrentMonth && isDisabled}
                      onClick={onDayClick}
                      data-text={date.format('D')}>
                      {date.format('D')}
                  </button>
              )}
          </time>
      </div>
  );
};
