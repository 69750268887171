
import { CalendarCurrentSelection } from "enums/CalendarQuickFilters";

export const useDateProps = ({
    todayDate,
    startDay,
    endDay,
    hoveredDay,
    disablePast,
    disableFuture,
    holidayDates = [],
    disabledDates = [],
    currentSelection,
    date
}) => {
    const eighteenMonthsAgo = todayDate.clone().subtract(18, 'months');
    const getDateInfo = (day) => {
        const isToday = day.isSame(todayDate, 'day');

        const isSelectionStart = startDay ? day.isSame(startDay, 'day') : false;

        const isSelectionEnd = endDay ? day.isSame(endDay, 'day') : false;

        const isHoveredForSelectionStart = hoveredDay && !endDay && day.isSame(hoveredDay, 'day');

        const isHoveredForSelectionEnd = hoveredDay && endDay && day.isSame(hoveredDay, 'day');

        const isCurrentMonth = day.month() === date.month();

        const isDisabled =
            (disablePast && day.isBefore(todayDate) && !isToday) ||
            (disableFuture && day.isAfter(todayDate) && !isToday) ||
            !isCurrentMonth ||
            holidayDates.find(holiday => holiday.isSame(day)) !== undefined ||
            disabledDates?.includes(day) ||
            day.isBefore(eighteenMonthsAgo, 'day');

        return {
            isToday,
            isSelectionStart,
            isSelectionEnd,
            isHoveredForSelectionStart,
            isHoveredForSelectionEnd,
            isCurrentMonth,
            isDisabled,
        };
    };

    return { getDateInfo };
};
