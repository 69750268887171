import React, { useEffect, useState, useReducer, useCallback, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { getInvoices, clearInvoices } from 'redux/action/invoices';
import { downloadInvoice, downloadMultipleInvoices } from 'services/invoices';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Pagination from 'components/pagination';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import CustomTextFieldFilter from 'components/CustomTextFieldFilter';
import { CustomToast } from 'components/CustomToast';
import { getFormattedInvoices } from 'helpers/getInvoices';
import Shimmer from 'components/shimmer';
import { ModalBodyComponent } from './ModalBodyComponent/ModalBodyComponent';
import { FileFormat, FileTypes } from 'enums/FileTypes';
import { ToastTypes } from 'enums/ToastTypes';
import { ReactComponent as DownloadIcon } from 'images/icons/download.svg';
import { ReactComponent as CheckMark } from 'images/icons/checkmark_orange.svg';
import { ReactComponent as NotFoundIcon } from 'images/icons/notFound.svg';

import dayjs from 'dayjs';
import invoiceFilterReducer from 'redux/reducers/invoiceFilterReducer';

import './styles.scss';
import { toast } from 'react-toastify';

const MAX_SIZE_MB = 200;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1048576;

const CustomCheckbox = ({ checked, handleToggle, disabled }) => {
    return (
        <label className='customCheckboxStyle'>
            <input type='checkbox' checked={checked} onChange={handleToggle} disabled={disabled} />
            <span className='checkboxCheckmarkStyle'>
                <CheckMark />
            </span>
        </label>
    );
};

const ActionCell = ({row, handleDownloadClick}) => {
    const { t } = useTranslation();
    const [isPdfDownloadLoading, setIsPdfDownloadLoading] = useState(false);
    const [isCsvDownloadLoading, setIsCsvDownloadLoading] = useState(false);

    const downloadPdfText = isPdfDownloadLoading ? t('DOWNLOADING') : `${t('DOWNLOAD')} ${t('PDF')}`;
    const downloadCsvText = isCsvDownloadLoading ? t('DOWNLOADING') : `${t('DOWNLOAD')} ${t('CSV')}`;

    const handleOnDownloadPdfClick = () => {
        if (isPdfDownloadLoading) {
            return null;
        }

        handleDownloadClick(row, FileTypes.PDF, setIsPdfDownloadLoading);
    };

    const handleOnDownloadCsvClick = () => {
        if (isCsvDownloadLoading) {
            return null;
        }

        handleDownloadClick(row, FileTypes.CSV, setIsCsvDownloadLoading);
    };

    return (
        <div className='actionCell'>
            <div className={`downloadLinkWrapper ${isPdfDownloadLoading ? 'disabled' : ''}`}>
                <span
                    onClick={() => handleOnDownloadPdfClick()}
                >
                {downloadPdfText}
            </span>
            </div>
            <div className={`downloadLinkWrapper ${isCsvDownloadLoading ? 'disabled' : ''}`}>
                <span
                    onClick={() => handleOnDownloadCsvClick()}
                >
                {downloadCsvText}
            </span>
            </div>
        </div>
    );   
};

const Payport = ({ tsp_ext_id = '' }) => {
    const pageSize = 20;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const invoices = useSelector(state => state.invoices?.data || []);
    const invoicesLoading = useSelector(state => state.invoices?.loading);

    let queryParams = queryString.parse(location.search);

    const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').startOf('day'));
    const [endDate, setEndDate] = useState(dayjs());

    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);

    const [selectedInvoiceNumbers, setSelectedInvoiceNumbers] = useState([]);
    const [selectedPdfFileIds, setSelectedPdfFileIds] = useState([]);
    const [selectedInvoicesPdfFileSize, setSelectedInvoicesPdfFileSize] = useState(0);
    const [selectedCsvFileIds, setSelectedCsvFileIds] = useState([]);
    const [selectedInvoicesCsvFileSize, setSelectedInvoicesCsvFileSize] = useState(0);
    const [isSelectedDownloadModalOpen, setIsSelectedDownloadModalOpen] = useState(false);
    const [selectedFormat, setSelectedFormat] = useState(FileTypes.NONE);
    const [toastType, setToastType] = useState(ToastTypes.None);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);

    const handleFormatChange = format => {
        setSelectedFormat(format);
    };

    const [filters, dispatchFilters] = useReducer(invoiceFilterReducer, {
        invoice_number: queryParams.invoice_number,
    });

    // Pagination
    const getPage = () => (queryParams.page ? parseInt(queryParams.page) : 1);
    const [currentPage, setCurrentPage] = useState(getPage());

    const loadInvoices = useCallback(() => {
        dispatch(
            getInvoices({
                tsp_id: tsp_ext_id,
                start_date: startDate,
                end_date: endDate,
                limit: pageSize,
                offset: (currentPage - 1) * pageSize,
                ...(filters.invoice_number ? { invoice_number: filters.invoice_number } : {}),
            })
        );
    }, [dispatch, tsp_ext_id, currentPage, startDate, endDate, filters]);

    const updatedInvoices = useMemo(() => {
        return getFormattedInvoices(invoices);
    }, [invoices]);

    const goToPage = pageNumber => {
        if (pageNumber > 0) {
            setCurrentPage(pageNumber);

            history.push({
                pathname: location.pathname,
                search: queryString.stringify({ ...queryParams, page: pageNumber }),
            });
        }
    };

    //Invoices
    useEffect(() => {
        if (startDate && endDate) {
            loadInvoices();
        }
        return () => {
            dispatch(clearInvoices());
        };
    }, [loadInvoices, tsp_ext_id, filters.invoice_number, startDate, endDate]);

    // page change
    useEffect(() => {
        setCurrentPage(queryParams.page ? parseInt(queryParams.page) : 1);
    }, [queryParams.page]);

    //Path, search
    useEffect(() => {
        history.push({
            pathname: location.pathname,
            search: queryString.stringify({ ...filters, page: currentPage }),
        });
    }, [filters, history, location.pathname, currentPage]);

    // Calculate the current page's invoices
    const startIndex = 0;
    const currentPageInvoices = useMemo(() => {
        return invoices.slice(startIndex, startIndex + pageSize);
    }, [invoices, startIndex, currentPage]);

    const handleRowSelection = useCallback(
        invoiceNumber => {
            setSelectedInvoiceNumbers(prevSelected => {
                const isSelected = prevSelected.includes(invoiceNumber);
                const updatedSelectedInvoices = isSelected
                    ? prevSelected.filter(id => id !== invoiceNumber)
                    : [...prevSelected, invoiceNumber];

                setSelectedPdfFileIds(prevPdfFileIds => {
                    const invoice = updatedInvoices.find(inv => inv.invoice_number === invoiceNumber);
                    if (isSelected) {
                        // Uncheck - remove the file_id
                        return prevPdfFileIds.filter(pdfId => pdfId !== invoice?.pdf?.file_id);
                    } else {
                        // Check - add the file_id
                        return invoice && invoice.pdf?.file_id
                            ? [...prevPdfFileIds, invoice.pdf.file_id]
                            : prevPdfFileIds;
                    }
                });
                setSelectedInvoicesPdfFileSize(prevFileSize =>{
                    const invoice = updatedInvoices.find(inv => inv.invoice_number === invoiceNumber);
                    if (isSelected) {
                        return prevFileSize - invoice?.pdf?.file_size;
                    }
                    else{
                        return prevFileSize + invoice?.pdf?.file_size;
                    }
                });
                setSelectedCsvFileIds(prevCsvFileIds => {
                    const invoice = updatedInvoices.find(inv => inv.invoice_number === invoiceNumber);
                    if (isSelected) {
                        // Uncheck - remove the file_id
                        return prevCsvFileIds.filter(pdfId => pdfId !== invoice?.csv?.file_id);
                    } else {
                        // Check - add the file_id
                        return invoice && invoice.csv?.file_id
                            ? [...prevCsvFileIds, invoice.csv.file_id]
                            : prevCsvFileIds;
                    }
                });
                setSelectedInvoicesCsvFileSize(prevFileSize =>{
                    const invoice = updatedInvoices.find(inv => inv.invoice_number === invoiceNumber);
                    if (isSelected) {
                        return prevFileSize - invoice?.csv?.file_size;
                    }
                    else{
                        return prevFileSize + invoice?.csv?.file_size;
                    }
                });

                return updatedSelectedInvoices;
            });
        },
        [updatedInvoices]
    );

    const handleSelectAllRows = useCallback(() => {
        const currentInvoiceNumbers = [...new Set(currentPageInvoices.map(invoice => invoice.invoice_number))];
        const currentPagePdfFileIds = updatedInvoices.map(invoice => invoice.pdf?.file_id).filter(fileId => !!fileId);

        const currentPagePdfFileSize = updatedInvoices.reduce((sum, invoice) => {
            const pdfFileSize= invoice.pdf ? invoice.pdf.file_size : 0;
            return sum + (pdfFileSize || 0);
        },0);

        const currentPageCsvFileSize = updatedInvoices.reduce((sum, invoice) => {
            const csvFileSize= invoice.csv ? invoice.csv.file_size : 0;
            return sum + (csvFileSize || 0);
        },0);

        const selectedInvoicesPdfFileSize = updatedInvoices.filter(invoice => selectedInvoiceNumbers.includes(invoice.invoice_number))
        .reduce((sum,invoice) => {
            const pdfFileSize = invoice.pdf ? invoice.pdf.file_size : 0;
            return sum + (pdfFileSize || 0);
        },0);

        const selectedInvoicesCsvFileSize = updatedInvoices.filter(invoice => selectedInvoiceNumbers.includes(invoice.invoice_number))
        .reduce((sum,invoice) => {
            const csvFileSize = invoice.csv ? invoice.csv.file_size : 0;
            return sum + (csvFileSize || 0);
        },0);

        const currentPageCsvFileIds = updatedInvoices.map(invoice => invoice.csv?.file_id).filter(fileId => !!fileId);

        const allSelected = currentInvoiceNumbers.every(invoiceNumber =>
            selectedInvoiceNumbers.includes(invoiceNumber)
        );
        
        if (allSelected) {
            // Deselect all current page invoices
            setSelectedInvoiceNumbers(prevSelected =>
                prevSelected.filter(invoiceNumber => !currentInvoiceNumbers.includes(invoiceNumber))
            );
            setSelectedPdfFileIds(prevPdfFileIds =>
                prevPdfFileIds.filter(pdfFileId => !currentPagePdfFileIds.includes(pdfFileId))
            );
            setSelectedCsvFileIds(prevCsvFileIds =>
                prevCsvFileIds.filter(csvFileId => !currentPagePdfFileIds.includes(csvFileId))
            );

            setSelectedInvoicesPdfFileSize(prevFileSize => prevFileSize - currentPagePdfFileSize);
            setSelectedInvoicesCsvFileSize(prevFileSize => prevFileSize - currentPageCsvFileSize);

        } else {
            // Select all current page invoices
            setSelectedInvoiceNumbers(prevSelected => [...new Set([...prevSelected, ...currentInvoiceNumbers])]);
            setSelectedPdfFileIds(prevPdfFileIds => [...new Set([...prevPdfFileIds, ...currentPagePdfFileIds])]);
            setSelectedCsvFileIds(prevCsvFileIds => [...new Set([...prevCsvFileIds, ...currentPageCsvFileIds])]);
            setSelectedInvoicesPdfFileSize(prevFileSize => prevFileSize - selectedInvoicesPdfFileSize + currentPagePdfFileSize);
            setSelectedInvoicesCsvFileSize(prevFileSize => prevFileSize - selectedInvoicesCsvFileSize + currentPageCsvFileSize);
        }
    }, [currentPageInvoices, selectedInvoiceNumbers, updatedInvoices]);


    const allSelected =
        currentPageInvoices.length > 0 &&
        currentPageInvoices.every(invoice => selectedInvoiceNumbers.includes(invoice.invoice_number));

    const openDownloadSelectedModal = () => {
        setIsSelectedDownloadModalOpen(true);
    };

    const closeDownloadSelectedModal = () => {
        setIsSelectedDownloadModalOpen(false);
        setSelectedFormat(FileTypes.NONE);
    };

    const applyDateRange = () => {
        updateInvoiceNumberFilter('');
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setCurrentPage(1);
    };

    const handleDownloadClick = async (invoice, fileType, setIsFileDownloading) => {
        if (invoice) {
            try {
                setIsFileDownloading(true);
                const response = await downloadInvoice(fileType === FileTypes.PDF ? invoice.pdf.file_id : invoice.csv.file_id);

                let fileName = '';

                if (fileType === FileTypes.PDF) {
                    fileName = `invoice_${invoice.invoice_number}.pdf`;
                } else if (fileType === FileTypes.CSV) {
                    fileName = `invoice_${invoice.invoice_number}.csv`;
                }

                const invoiceFile = new File([response.data], fileName, { type: fileType });

                const blobUrl = window.URL.createObjectURL(invoiceFile);
                const newWindow = window.open(blobUrl);
                if (newWindow) {
                    setTimeout(() => {
                        newWindow.document.title = invoiceFile.name;
                    }, 1000);
                } else {
                    console.error('Popup blocked or failed to open');
                }
            } catch (err) {
                toast.error(t('ERROR_MESSAGE'));
            } finally {
                setIsFileDownloading(false);
            }
        }
    };

    const handleMultipleDownloadClick = async fileType => {
        setShowSpinner(true);
        if (selectedInvoiceNumbers) {
            const requestBody = { file_id_list: fileType === FileTypes.PDF ? selectedPdfFileIds : selectedCsvFileIds };
            try {
                const response = await downloadMultipleInvoices(requestBody);
                const fileFormat = fileType === FileTypes.PDF ? FileFormat.PDF : FileFormat.CSV;

                if (response.status === 200) {
                    const invoicesZip = new File([response.data], 'invoices.zip', { type: FileTypes.ZIP });

                    const blobUrl = window.URL.createObjectURL(invoicesZip);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'invoices.zip';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);

                    const fileCount = selectedInvoiceNumbers.length;
                    setToastMessage(t('INVOICES_DOWNLOADED', { noOfInvoices: fileCount, fileType: fileFormat }));
                    setToastType(ToastTypes.Success);
                    setIsToastVisible(true);
                    setTimeout(() => {
                        setIsToastVisible(false);
                    }, 4000);
                }
            } catch (err) {
                setToastMessage(t(`ERROR_MESSAGE`));
                setIsToastVisible(true);
                setToastType(ToastTypes.Error);
                setTimeout(() => {
                    setIsToastVisible(false);
                }, 3000);
            }
        }
        setShowSpinner(false);
        closeDownloadSelectedModal();
        setSelectedInvoiceNumbers([]);
        setSelectedCsvFileIds([]);
        setSelectedPdfFileIds([]);
    };

    const isDownloadLimitExceeded = useCallback((selectedFormat) => {
        if (selectedFormat === FileTypes.PDF) {
            return selectedInvoicesPdfFileSize > MAX_SIZE_BYTES; 
        } else if (selectedFormat === FileTypes.CSV) {
            return selectedInvoicesCsvFileSize > MAX_SIZE_BYTES;
        } else {
            return false;
        }
    }, [selectedInvoicesPdfFileSize, selectedInvoicesCsvFileSize]);

    const areInvoicesLoading = !invoices || invoicesLoading;
    const invoiceTableColumns = useMemo(
        () => [
            {
                dataField: 'invoice_number',
                csvExport: false,
                text: t('INVOICE_NUMBER'),
                classes: 'invoicesTableCell',
                headerClasses: 'invoicesTableColumn',
                headerStyle: () => {
                    return { width: '190px' };
                },
            },
            {
                dataField: 'issue_date',
                csvExport: false,
                classes: 'invoicesTableCell',
                headerClasses: 'invoicesTableColumn',
                text: t('ISSUE_DATE'),
                sort: true,
                formatter: cell => {
                    return dayjs(cell).format('D MMM YYYY');
                },
                headerStyle: () => {
                    return { width: '580px' };
                },
            },
            {
                dataField: 'action',
                csvExport: false,
                classes: 'invoicesTableCell',
                headerClasses: 'invoicesTableColumn',
                text: t('ACTION'),
                formatter: (_, row) => {
                    return (
                        <ActionCell row={row} handleDownloadClick={handleDownloadClick}/>
                    );
                },
                headerStyle: () => {
                    return { width: '220px' };
                },
            },
        ],
        [handleDownloadClick, t]
    );

    const isNextDisabled = currentPageInvoices.length < pageSize;

    const isPreviousDisabled = currentPage === 1;

    function updateInvoiceNumberFilter(invoiceNumber) {
        dispatchFilters({
            type: 'INVOICE_NUMBER',
            payload: invoiceNumber,
        });
        setCurrentPage(1);
    }
    const showInvoicesTable = props => {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selectColumnStyle: { width: '56px', verticalAlign: 'middle' },
            selectionRenderer: ({ rowKey }) => {
                const file_id = rowKey;

                return (
                    <div className='checkbox-column'>
                        <CustomCheckbox
                            key={file_id}
                            checked={selectedInvoiceNumbers.includes(file_id)}
                            handleToggle={() => handleRowSelection(file_id)}
                        />
                    </div>
                );
            },
            selectionHeaderRenderer: () => {
                return (
                    <div className='checkbox-column'>
                        <CustomCheckbox checked={allSelected} handleToggle={() => handleSelectAllRows()} />
                    </div>
                );
            },
            style: { header: 'yellow' },
        };
        return invoices?.length > 0 ? (
            <div className='invoiceTableWrapper'>
                <div className='invoicesHeadingContainer'>
                    <h3 className='invoiceHeading'>{t('INVOICES')}</h3>
                    <div
                        className={`invoicesSelectedDownload ${selectedInvoiceNumbers.length === 0 ? 'disabled' : ''}`}
                        onClick={selectedInvoiceNumbers.length > 0 ? openDownloadSelectedModal : null}>
                        <div className='iconClass'>
                            <DownloadIcon />
                        </div>
                        <div className='downloadSelectedContainer'>{t('DOWNLOAD_SELECTED')}</div>
                    </div>
                </div>
                <Modal
                    dialogClassName='modalClass'
                    onHide={closeDownloadSelectedModal}
                    show={isSelectedDownloadModalOpen}
                    animation={false}>
                    <Modal.Header closeButton className='custom-modal-header' />
                    <Modal.Body>
                        <ModalBodyComponent
                            selectedFormat={selectedFormat}
                            onFormatChange={handleFormatChange}
                            handleDownloadClick={() => handleMultipleDownloadClick(selectedFormat)}
                            showSpinner={showSpinner}
                            isDownloadLimitExceeded={isDownloadLimitExceeded}
                        />
                    </Modal.Body>
                </Modal>
                <BootstrapTable
                    classes='invoices-table'
                    noDataIndication={t('NO_DATA_FOUND')}
                    defaultSorted={[
                        {
                            dataField: 'issue_date',
                            csvExport: false,
                            order: 'desc',
                        },
                    ]}
                    selectRow={selectRow}
                    {...props.baseProps}
                />
                {invoices && invoices.length > 0 && (
                    <Pagination
                        zeroBasedPageIndex={false}
                        currentPage={currentPage}
                        goToPage={goToPage}
                        list={currentPageInvoices}
                        pageSize={pageSize}
                        nextDisabled={isNextDisabled}
                        previousDisabled={isPreviousDisabled}
                    />
                )}
            </div>
        ) : filters.invoice_number ? (
            <div className='notFoundWrapper'>
                <NotFoundIcon />
                <div className='invoice-error'>
                    {t('NO_RESULTS_FOUND_FOR_INVOICE_SEARCH', { invoice_number: filters.invoice_number })}
                </div>
                <div className='invoice-error-action'>{t('SEARCH_FOR_ANOTHER_INVOICE_NUMBER')}</div>
            </div>
        ) : (
            <div className='notFoundWrapper'>
                <NotFoundIcon />
                <div className='notFoundFontClass'>{t('NO_RESULTS_FOUND_FOR_DATE_RANGE_SEARCH')}</div>
                <div className='invoice-error-action'>{t('SEARCH_FOR_ANOTHER_DATE_RANGE')}</div>
            </div>
        );
    };

    return (
        <div className='invoices'>
            <div>
                <div className='reportsWrapper'>
                    <h2 className='page-title'>{t('PAYMENT_REPORTS')}</h2>
                    {isToastVisible && (
                        <CustomToast
                            message={toastMessage}
                            toastType={toastType}
                            onClose={() => setIsToastVisible(false)}
                        />
                    )}
                </div>
                <div className='invoiceFilters'>
                    <DateRangePicker
                        numberOfMonths={2}
                        hasNavigation
                        disableFuture={true}
                        startDay={tempStartDate}
                        setStartDay={setTempStartDate}
                        endDay={tempEndDate}
                        setEndDay={setTempEndDate}
                        onApplyButtonClick={applyDateRange}
                    />
                    <CustomTextFieldFilter
                        value={filters.invoice_number}
                        updateTextFieldFilter={updateInvoiceNumberFilter}
                        isRideIdFilter={false}
                        placeholder={t('SEARCH_BY_INVOICE_NUMBER')}
                    />
                </div>
            </div>
            <ToolkitProvider keyField='invoice_number' data={updatedInvoices} columns={invoiceTableColumns}>
                {props => <>{areInvoicesLoading ? <Shimmer /> : showInvoicesTable(props)}</>}
            </ToolkitProvider>
        </div>
    );
};

export default Payport;
