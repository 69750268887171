import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Spanish
import 'dayjs/locale/fr'; // French
import 'dayjs/locale/it'; // Italian
import 'dayjs/locale/en'; // English (default)
import 'dayjs/locale/de';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'contexts/language';
import { ReactComponent as CheckMark } from 'images/icons/checkmark.svg';
import { CalendarQuickFilters } from 'enums/CalendarQuickFilters';
import './styles.scss';

export const DatePickerFilters = ({ selectedFilter, onFilterChange }) => {
    const { t } = useTranslation();
	const [language] = useContext(LanguageContext);
    
    const handleFilterChange = filter => {
        onFilterChange(filter);
    };
    const last30DaysStartDate = dayjs().subtract(30, 'days').locale(language).format('DD MMM YY');
    const rangeEndDate = dayjs().locale(language).format('DD MMM YY');
    const last3MonthsStartDate = dayjs().subtract(3, 'months').locale(language).format('DD MMM YY');
    const currentYearStartDate = dayjs().startOf('year').locale(language).format('DD MMM YY');

    return (
        <div className='datePickerFilters'>
            <div className='dateRangeHeading'>{t('SELECT_DATE_PERIOD')}</div>
            <div className='filterColumn'>
                <label className='custom-radio'>
                    <input
                        type='radio'
                        name='dateFilter'
                        value={CalendarQuickFilters.last30Days}
                        checked={selectedFilter === CalendarQuickFilters.last30Days}
                        onChange={() => handleFilterChange(CalendarQuickFilters.last30Days)}
                    />
                    <span className='checkmark'>
                        <CheckMark />
                    </span>
                    {t('LAST_30_DAYS')}
                </label>
                <div className='dateRange'>
                    {last30DaysStartDate} - {rangeEndDate}
                </div>
            </div>
            <div className='filterColumn'>
                <label className='custom-radio'>
                    <input
                        type='radio'
                        name='dateFilter'
                        value={CalendarQuickFilters.last3Months}
                        checked={selectedFilter === CalendarQuickFilters.last3Months}
                        onChange={() => handleFilterChange(CalendarQuickFilters.last3Months)}
                    />
                    <span className='checkmark'>
                        <CheckMark />
                    </span>
                    {t('LAST_3_MONTHS')}
                </label>
                <div className='dateRange'>
                    {last3MonthsStartDate} - {rangeEndDate}
                </div>
            </div>
            <div className='filterColumn'>
                <label className='custom-radio'>
                    <input
                        type='radio'
                        name='dateFilter'
                        value={CalendarQuickFilters.currentYear}
                        checked={selectedFilter === CalendarQuickFilters.currentYear}
                        onChange={() => handleFilterChange(CalendarQuickFilters.currentYear)}
                    />
                    <span className='checkmark'>
                        <CheckMark />
                    </span>
                    {t('CURRENT_YEAR')}
                </label>
                <div className='dateRange'>
                    {currentYearStartDate} - {t('TODAY')}
                </div>
            </div>
            <div className='filterColumn'>
                <label className='custom-radio'>
                    <input
                        type='radio'
                        name='dateFilter'
                        value={CalendarQuickFilters.customRange}
                        checked={selectedFilter === CalendarQuickFilters.customRange}
                        onChange={() => handleFilterChange(CalendarQuickFilters.customRange)}
                    />
                    <span className='checkmark'>
                        <CheckMark />
                    </span>
                    {t('CUSTOM')}
                </label>
                <div className='dateRange'>{t("MAX_TIME_PERIOD")}</div>
            </div>
        </div>
    );
};


