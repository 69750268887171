export const isEmpty = (value) => {
    return value === null || value === undefined || value === ''
        || (Array.isArray(value) && value.length === 0);
};

export const isIdValid = (value, verified, idFromBE) => {
    return !isEmpty(value) && (idFromBE === value || verified);
};

export const isPayportInvoiceNumberInvalid = value => {
    const notAllowedChars = ['e', 'E', '-', '.', '+'];

    if (notAllowedChars.includes(value)) {
        return true;
    }
    return false;
};