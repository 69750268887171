import React from 'react';
import './styles.scss';
import { ToastTypes } from 'enums/ToastTypes';

export const CustomToast = ({ message, onClose, toastType }) => {
    let toastColorStyles;
    if (toastType === ToastTypes.Success) {
        toastColorStyles = {
            toast: { color: '#00AC4F', backgroundColor: '#E7FAF0', border: '1px solid #00AC4F' },
            closeButton: {
                color: '#00AC4F',
            },
        };
    } else {
        toastColorStyles = {
            toast: { color: '#D32F2F', backgroundColor: '#FFEDED', border: '1px solid #D32F2F' },
            closeButton: {
                color: '#D32F2F',
            },
        };
    }

    return (
        <div className='toast-container' style={toastColorStyles.toast}>
            <span>{message}</span>
            <button onClick={onClose} className='close-button' style={toastColorStyles.closeButton}>
                &#x2715;
            </button>
        </div>
    );
};
