import React, { useEffect, useState } from 'react';
import { isPayportInvoiceNumberInvalid } from 'helpers';
import { ReactComponent as SearchIcon } from '../../images/icons/ico_magnifier.svg';

import './styles.scss';

const CustomTextFieldFilter = ({ value: _value, updateTextFieldFilter, placeholder }) => {
    const [value, setValue] = useState(_value);

    useEffect(() => {
        setValue(_value);
    }, [_value]);

    function handleEnterKey(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            updateTextFieldFilter(value);
        }
    }

    const handleKeyDown = e => {
        handleEnterKey(e);

        if (isPayportInvoiceNumberInvalid(e.key)) {
            e.preventDefault();
        }
    };

    const clearInput = () => {
        setValue('');
        updateTextFieldFilter('');
    };

    const borderColor = value ? '#191919' : '#ccc';
    const fontColor = value ? '#191919' : '#A4A4A4';

    const handleInputChange = e => {
        const slicedValue = e.target.value.slice(0, 12);
        setValue(slicedValue);
        if (slicedValue === '') {
            updateTextFieldFilter('');
        }
    };

    return (
        <div className='input-wrapper'>
            <span className='invoice-search-icon'>
                <SearchIcon />
            </span>
            <input
                className='inputClass'
                value={value}
                placeholder={placeholder}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{ borderColor: borderColor, color: fontColor }}
                type='number'
                maxLength={12}
            />
            {value && (
                <span className='clearIcon' onClick={clearInput}>
                    &#x2715;
                </span>
            )}
        </div>
    );
};

export default CustomTextFieldFilter;
