import times from 'lodash/times';
import dayjs from 'dayjs';

export const generateDateRows = date => {
    const year = date.year();
    const daysInMonth = date.daysInMonth();
    const rows = [[]];
    let rowIndex = 0;
    const daysInAWeek = 7;

    const firstDayOfWeek = dayjs().localeData().firstDayOfWeek();
    times(daysInMonth, dayIndex => {
        const currentDate = dayjs(new Date(year, date.month(), dayIndex + 1));
        const currentDayOfMonth = currentDate.day();
        rows[rowIndex].push(currentDate);
        const isCurrentDayLastDayOfWeek = currentDayOfMonth === (6 + firstDayOfWeek) % daysInAWeek;
        const isCurrentDayLastDayOfMonth = dayIndex !== daysInMonth - 1;
        if (isCurrentDayLastDayOfWeek && isCurrentDayLastDayOfMonth) {
            rowIndex += 1;
            rows.push([]);
        }
    });

    const rowLen = rows.length;
    const firstRow = rows[0];

    if (firstRow.length !== daysInAWeek) {
        const backfillBy = daysInAWeek - firstRow.length;
        const first = firstRow[0];
        times(backfillBy, backFillIndex => {
            const d = first.clone().startOf('week').add(backFillIndex, 'day');
            firstRow.splice(backFillIndex, 0, d);
        });
    }

    const lastRow = rows[rowLen - 1];
    if (lastRow.length !== daysInAWeek) {
        const fillBy = daysInAWeek - lastRow.length;
        const lastLen = lastRow.length;
        const last = lastRow[lastLen - 1];
        times(fillBy, fillIndex => {
            const d = last.clone().add(fillIndex + 1, 'day');
            lastRow.push(d);
        });
    }

    return rows;
};
