import React, { useEffect, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../images/icons/ico_magnifier.svg';

import './styles.scss';

const TextFieldFilter = ({ value: _value, updateTextFieldFilter, placeholder = 'Ride Id' }) => {
    const [value, setValue] = useState(_value);

    useEffect(() => {
        setValue(_value);
    }, [_value]);

    function handleEnterKey(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            updateTextFieldFilter(value);
        }
    }

    return (
        <div className='text-field-input-container'>
            <input
                value={value}
                placeholder={placeholder}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleEnterKey}
            />
            <span className='search-icon' onClick={() => updateTextFieldFilter(value)}>
                <SearchIcon />
            </span>
        </div>
    );
};

export default TextFieldFilter;