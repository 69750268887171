export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_ACTIVE_SUB_MENU = "SET_ACTIVE_SUB_MENU";

export const USER = "USER";
export const DISPATCHER_DETAILS = "DISPATCHER_DETAILS";
export const LOGOUT = "LOGOUT";
export const SUPPORT_NUMBERS = "SUPPORT_NUMBERS";
export const RESERVATIONS = "RESERVATIONS";
export const DRIVERS = "DRIVERS";
export const VEHICLES = "VEHICLES";
export const VEHICLE_CLASSES = "VEHICLE_CLASSES";
export const INVOICES = "INVOICES";

export const RESERVATIONS_LOADING = 'RESERVATIONS_LOADING';
export const RESERVATIONS_LOADED = 'RESERVATIONS_LOADED';

export const DRIVERS_LOADING = 'DRIVERS_LOADING';
export const DRIVERS_LOADED = 'DRIVERS_LOADED';

export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADED = 'INVOICES_LOADED';

export const VEHICLES_LOADING = 'VEHICLES_LOADING';
export const VEHICLES_LOADED = 'VEHICLES_LOADED';

export const ADMINUSER = "ADMINUSER";

export const CUSTOMER_TYPES = "CUSTOMER_TYPES";
export const COUNTRY = "COUNTRY";
export const CATEGORY = "CATEGORY";
export const NOTES = "NOTES";
export const MANUFACTURER_MODELS = "MANUFACTURER_MODELS";