import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import { useHover } from 'web-api-hooks';
import { CalendarDay } from '../CalendarDay';
import { CalendarWeekDays } from '../CalendarWeekDays';
import { generateDateRows } from 'helpers/generateDateRows';
import { useDateProps } from 'hooks/useDateProps';
import { CalendarCurrentSelection } from 'enums/CalendarQuickFilters';
import './styles.scss';

import { useTranslation } from 'react-i18next';

dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export const CalendarMonth = ({
    currentDate,
    startDay,
    setStartDay,
    endDay,
    setEndDay,
    hoveredDay,
    setHoveredDay,
    disablePast = false,
    disableFuture = false,
    snake,
    setHasRangeStarted,
    shouldDisableDay,
    currentSelection,
    index,
    onDateClick,
}) => {
    const { t } = useTranslation();
    const dateToConstruct = currentDate?.isValid() ? currentDate : dayjs();
    const date = useMemo(() => dateToConstruct.clone().add(index, 'month'), [dateToConstruct, index]);
    const todayDate = dayjs();
    const year = date.year();

    const calendarMonthRef = useRef(null);
    const [disabledDates, setDisabledDates] = useState([]);
    const [isHovered, bindHover] = useHover();

    const { getDateInfo } = useDateProps({
        todayDate,
        startDay,
        endDay,
        hoveredDay,
        disablePast,
        disableFuture,
        disabledDates,
        currentSelection,
        date,
    });
    const isSelected = day => {
        if (startDay && endDay && day.isSame(hoveredDay) && !day.isBetween(startDay, endDay, 'date', '[]')) {
            return true;
        }

        if (!startDay) {
            return false;
        }

        if (endDay) {
            return startDay && day.isBetween(startDay, endDay, 'date', '[]');
        }

        if (hoveredDay && !endDay) {
            return startDay && day.isSameOrAfter(startDay, 'date') && day.isSameOrBefore(hoveredDay);
        }

        return false;
    };

    const handleMouseEnter = day => {
        if (snake) {
            setHoveredDay(day && !(day.isSameOrAfter(startDay) && day.isSameOrBefore(endDay)) ? day : null);
        }
    };

    const snakeMode = day => {
        if (startDay && endDay) {
            //If after start and end Date are selected and another click happens , treat it as a new start date
            setStartDay(day);
            setEndDay(null);
        } else if (!startDay) {
            // First click always sets the start date
            setStartDay(day);
            setEndDay(null); // Reset end day
            setHasRangeStarted(false);
        } else if (day.isBefore(startDay)) {
            // If second click is before startDay, treat it as a new start date
            setStartDay(day);
            setHasRangeStarted(true);
        } else if (day.isAfter(startDay)) {
            // If second click is after startDay, set it as the end date
            setEndDay(day);
            setHasRangeStarted(true);
        }  
    };

    const rows = useMemo(() => generateDateRows(date), [date]);

    const calculateDisabledDays = useCallback(
        (isReturn, selectedPickupDate) => {
            const datesToDisable = [];
            // Here rows is a 2D array such that it contains each week and for each week it contains each day
            rows.forEach(week => {
                week.forEach(date => {
                    if (shouldDisableDay?.(date, isReturn, selectedPickupDate)) {
                        datesToDisable.push(date);
                    }
                });
            });
            // We go with setState here, so as to asynchronously set the disabled dates so that initial render wont be delayed
            setDisabledDates(datesToDisable);
        },
        [rows, shouldDisableDay]
    );

    const handleClick = (date, isDisabled) => {
        if (!isDisabled) {
            const day = date.clone();
            snakeMode(day);
            if (!endDay) {
                calculateDisabledDays(true, day);
            }
        }
        onDateClick?.(date, isDisabled, currentSelection);
    };

    useEffect(() => {
        if (shouldDisableDay && rows.length) {
            calculateDisabledDays(currentSelection === CalendarCurrentSelection.End);
        }
    }, [rows, currentSelection, calculateDisabledDays, shouldDisableDay]);

    useEffect(() => {
        // If hover is outside the date picker, then set hovered day to be null
        if (!isHovered) {
            setHoveredDay(null);
        }
    }, [isHovered, setHoveredDay]);

    return (
        <div className='calendarMonth' ref={calendarMonthRef} {...bindHover}>
            <time className='calendarMonth_MonthTitle' dateTime={date.format('YYYY-MM')}>
                <div className='calendarMonthTextContainer'>
                    {`${t(date.format('MMMM').toUpperCase())} ${year}`}
                    <hr className='horizontalLineClass' />
                </div>
            </time>
            <CalendarWeekDays />
            <div role='rowgroup'>
                {rows.map((row, rowIndex) => (
                    <div className='calendarMonth_DayRow' role='row' key={rowIndex}>
                        {row.map((day, dayIndex) => {
                            const {
                                isToday,
                                isSelectionStart,
                                isSelectionEnd,
                                isDisabled,
                                isHoveredForSelectionEnd,
                                isHoveredForSelectionStart,
                                isCurrentMonth,
                            } = getDateInfo(day);
                            return (
                                <CalendarDay
                                    key={dayIndex}
                                    date={day}
                                    isToday={isToday}
                                    isSelectionStart={isSelectionStart}
                                    isSelectionEnd={isSelectionEnd}
                                    isDisabled={isDisabled}
                                    onMouseEnter={handleMouseEnter}
                                    isSelected={
                                        isSelected(day) || isHoveredForSelectionEnd || isHoveredForSelectionStart
                                    }
                                    onClick={handleClick}
                                    isInCurrentMonth={isCurrentMonth}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};


