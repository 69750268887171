import React from "react";
import { useTranslation } from 'react-i18next';
import { FileTypes } from "enums/FileTypes";
import { ReactComponent as CheckMarkIcon } from 'images/icons/checkmark.svg';
import { ReactComponent as WarningIcon } from 'images/icons/warning.svg';
import { Button } from 'components/Button';
import './styles.scss';



export const ModalBodyComponent = ({ selectedFormat, onFormatChange, handleDownloadClick, showSpinner, isDownloadLimitExceeded}) => {

    const { t } = useTranslation();

    const handleFormatChange = format => {
        onFormatChange(format);
    };
    
    return (
        <div className='modalBodyWrapper'>
            <div className='modalBodyHeading'>{t('SELECT_FORMAT')}</div>
            <div className='fileFormatinput'>
                <div className='fileFormatClass'>
                    <label className='custom-format-radio'>
                        <input type='radio' value={FileTypes.PDF} checked={selectedFormat === FileTypes.PDF} onChange={() => handleFormatChange(FileTypes.PDF)} disabled={showSpinner}/>
                        <span className='checkmark'>
                            <CheckMarkIcon />
                        </span>
                        {t('PDF')}
                    </label>
                </div>
                <div className='fileFormatClass'>
                    <label className='custom-format-radio'>
                        <input type='radio' value={FileTypes.CSV} checked={selectedFormat === FileTypes.CSV} onChange={() => handleFormatChange(FileTypes.CSV)} disabled={showSpinner}/>
                        <span className='checkmark'>
                            <CheckMarkIcon />
                        </span>
                        {t('CSV')}
                    </label>
                </div>
            </div>
            {isDownloadLimitExceeded(selectedFormat) &&
                <div className='warningContainer'>
                    <WarningIcon />
                    <span className='warningDescriptionClass'>{t('DOWNLOAD_FILES_LIMIT')} </span>
                </div>}
            <Button disabled={selectedFormat === FileTypes.NONE || isDownloadLimitExceeded(selectedFormat)} text={t('DOWNLOAD')} className='downloadButtonClass' onClick={handleDownloadClick} isLoading={showSpinner}/>
        </div>
    );
};