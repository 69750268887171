import { goOrangeAxios } from './axios';

export const getInvoices = async function (params) {
    return goOrangeAxios.post(`v1/ride-invoice/payport/invoices`, {
        ...params,
    });
};

export async function downloadInvoice(fileId) {
    return goOrangeAxios.get(`v1/ride-invoice/invoice/${fileId}`, {
        responseType: 'blob',
    });
}

export async function downloadMultipleInvoices(params) {
    return goOrangeAxios.post(`v1/ride-invoice/payport/download-bulk-invoices`,params, {responseType: 'blob',});
    
}