import { INVOICES, INVOICES_LOADED, INVOICES_LOADING } from 'redux/action/types';

const initialState = {
    data: [],
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INVOICES: {
            return { ...state, data: action.invoices };
        }
        case INVOICES_LOADING: {
            return { ...state, loading: true };
        }
        case INVOICES_LOADED: {
            return { ...state, loading: false };
        }
        default:
            return state;
    }
};
