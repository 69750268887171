import { FileTypes } from "enums/FileTypes";

export function getFormattedInvoices(invoices) {
    const groupedInvoices = {};
    invoices.forEach(invoice => {
        const key = `${invoice.invoice_number}-${invoice.issue_date.slice(0, 13)}`;

        if (!groupedInvoices[key]) {
            groupedInvoices[key] = {
                invoice_number: invoice.invoice_number,
                issue_date: invoice.issue_date,
                pdf: null,
                csv: null,
            };
        }
        if (invoice.mime_type === FileTypes.PDF) {
            groupedInvoices[key].pdf = { file_id: invoice.file_id, file_size: invoice.file_size };
        } else if (invoice.mime_type === FileTypes.CSV) {
            groupedInvoices[key].csv = { file_id: invoice.file_id, file_size: invoice.file_size };
        }
    });
    return Object.values(groupedInvoices);
}
